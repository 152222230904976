<!-- 企业理念 -->
<template>
  <div class="Honor">
    <div class="topText">
      <div class="title">{{ honor.title }}</div>
    </div>
    <!-- 内容区域 flex-between flex-align-->
    <div class="content">
      <div
        v-for="(item, index) in honor.steps"
        :key="index"
        class="honor-item"
      >
        <img :src="item.img" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      honor: {
        title: '荣誉资质',
        steps: [
          // {
          //   img: 'https://static.zhongjiadata.com/images/20211029/59446a89e716483a89e0ee2a8f206454.jpg'
          // }, {
          //   img: 'https://static.zhongjiadata.com/images/20211029/563085ef963f4577a6b9378261992b5a.jpg'
          // }, {
          //   img: 'https://static.zhongjiadata.com/images/20211029/96f1096a10534818a5ae4caaff33ad47.jpg'
          // }, {
          //   img: 'https://static.zhongjiadata.com/images/20211029/a60257c43929469b91d2b1d78b1917d8.jpg'
          // }, {
          //   img: 'https://static.zhongjiadata.com/images/20211029/88fa8747f8dd458a9461309774678050.jpg'
          // }, {
          //   img: 'https://static.zhongjiadata.com/images/20211029/560622f313f0419787bfe96185697612.jpg'
          // }, {
          //   img: 'https://static.zhongjiadata.com/images/20211029/37327c382709422c90c1576c54358d43.jpg'
          // }
        ]
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.forArr();
  },
  mounted() {},
  methods: {
    forArr() {
      for (let index = 0; index < 21; index++) {
        this.honor.steps.push({
          img: require(`@/assets/honr/honr_${index + 1}.png`)
        });
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}

.Honor {
  margin-bottom: 200px;
  .topText {
    padding-top: 154px;
    height: auto;
    margin-bottom: 28px;
    .title {
      margin: 0;
      color: #333333;
      line-height: 50px;
      font-size: 36px;
      font-weight: 600;
    }
    .sub {
      font-size: 26px;
      color: #333333;
      line-height: 26px;
      font-weight: normal;
    }
  }
  .content {
    margin: 0 auto;
    width: 1280px;
    min-height: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-center;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    .honor-item {
      width: 163px;
      height: 232px;
      margin-bottom: 40px;
    }
    // .honor-item {
    //   width: 170px;
    //   height: 234px;
    //   flex: 7;
    //   position: relative;
    //   .item-content {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     padding: 80px 40px;
    //     .title {
    //       width: 100%;
    //       line-height: 36px;
    //       font-size: 36px;
    //       font-weight: 700;
    //       text-align: center;
    //       color: #ffffff;
    //       margin-bottom: 60px;
    //     }
    //     .content {
    //       width: 100%;
    //       font-size: 16px;
    //       font-weight: 400;
    //       text-align: center;
    //       color: #ffffff;
    //       line-height: 36px;
    //     }
    //   }
    // }
  }
}
</style>
