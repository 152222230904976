<!-- 企业理念 -->
<template>
  <div class="Concept">
    <div class="topText">
      <div class="title">{{ concept.title }}</div>
    </div>
    <!-- 内容区域 -->
    <div class="content">
      <div
        v-for="(item, index) in concept.steps"
        :key="index"
        class="concept-item"
      >
        <div class="title">{{ item.title }}</div>
        <img class="img" :src="item.img" alt="">
        <div class="item-content">
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="bg" />
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      concept: {
        title: '企业理念',
        steps: [
          {
            title: '诚信合作 共赢未来',
            img: require('@/assets/img/concept1.png'),
            content: '中嘉数科本着诚信、专业、开放的态度，积极对外开展合作，乐于打破闭门造车的行业现状，以共赢的态度发展企业'
          }, {
            title: '以人为本 能者为师',
            img: require('@/assets/img/concept2.png'),
            content: '公司以人性化的管理开展经营，凡公司的各项制度，工作流程均让员工参与制定，切实让员工感受到公司是真正的大家庭'
          }, {
            title: '数字孪生 创新发展',
            img: require('@/assets/img/concept3.png'),
            content: '公司以数据为核心，数字孪生、以人为本为理念朝着智慧城市建设为目标，全力拓展市场，研发心产品，树立高新企业标杆'
          }
        ]
      }
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.Concept {
  position: relative;
  margin-bottom: 10px;
  .topText {
    padding-top: 100px;
    height: auto;
    margin-bottom: 60px;
    .title {
      margin: 0;
      color: #333333;
      line-height: 50px;
      font-size: 36px;
      font-weight: 600;
    }
    .sub {
      font-size: 26px;
      color: #333333;
      line-height: 26px;
      font-weight: normal;
    }
  }
  .content {
    margin: 0 auto;
    width: 1280px;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    .concept-item {
      width: 400px;
      height: 320px;
      position: relative;
      background: #2e61ff;
      border-radius: 4px;
      text-align: center;
      padding-top: 32px;
      .title {
        width: 100%;
        line-height: 37px;
        font-size: 26px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .img {
        width: 340px;
        height: 200px;
      }
      .item-content {
        width: 340px;
        height: 200px;
        position: absolute;
        top: 90px;
        left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 24px;
        .content {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
  }
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -80px;
    height: 160px;
    background: #1d2437;
    z-index: -1;
  }
}
</style>
