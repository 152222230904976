<template>
  <div class="wrapper pageBox about">
    <div class="banner">
      <div class="banner-text">
        <div class="title">公司介绍</div>
        <div class="sub">
          <div class="first">中嘉数科</div>
          <div>数字赋能未来生活</div>
        </div>
        <div class="tip">
          医院更智慧  管理更及时  服务更贴心
        </div>
      </div>
      <!-- <div class="bannerText">
        <div class="title">企业介绍</div>
        <div class="btn"><div class="company">中嘉数科</div><div class="colLine" />数字赋能未来生活</div>
      </div> -->
    </div>
    <!-- 公司简介 -->
    <div class="topText company">
      <div class="title">公司简介</div>
    </div>
    <div class="companyText">
      <div class="left">
        <!-- <div class="title">公司简介</div> -->
        <div class="text">中嘉数字科技（浙江）有限公司位于杭州市萧山区，是一家国资控股、提供全流程数字化转型升级解决方案的科技公司。我们以“数字赋能未来生活”为发展理念，致力于用数字化手段推动民生与健康服务、产业经济与企业服务、政府效能与治理、环境与低碳的发展，基于嘉服云整体架构，打造空间集成管控运维服务和数字化政企民服务两大集成产品体系。秉持着“以人为本，客户至上”的经营理念，逐步积累了智能化设计实施、物联网平台研发与系统集成、数字孪生可视化等关键技术经验和相关资质，业务涵盖了智慧园区、智慧病房、未来社区、智慧停车等空间科技服务领域和政企服务、企业服务的数字化改革领域。中嘉数科旨在切实解决空间与城市管理、民生与产业服务中关键性的痛点问题，促进数字化发展革新，打造产业新生态。</div>
        <div class="icon-box">
          <div class="icon-item">
            <img class="img" src="~@/assets/img/about1.png" alt="">
            <span>合作</span>
          </div>
          <div class="icon-item">
            <img class="img" src="~@/assets/img/about2.png" alt="">
            <span>开放</span>
          </div>
          <div class="icon-item">
            <img class="img" src="~@/assets/img/about3.png" alt="">
            <span>共赢</span>
          </div>
        </div>
      </div>
      <div class="right">
        <img src="https://static.zhongjiadata.com/images/20250227/0051594a0bdd4a94b4fdc98b5108ac36.png">
      </div>
    </div>
    <!-- 企业视频 -->
    <!-- <Video /> -->
    <!-- 大事记 -->
    <!-- <List /> -->
    <!-- 企业理念 -->
    <Concept />
    <!-- 荣誉资质 -->
    <Honor />
    <!-- 生态伙伴 -->
    <!-- <Companion /> -->
  </div>
</template>
<script>
// import Video from './components/video.vue';
// import List from './components/list.vue';
import Concept from './components/Concept.vue';
import Honor from './components/Honor.vue';
// import Companion from './components/Companion.vue';
export default {
  components: { Concept, Honor }
};
</script>
<style lang="scss" scoped>
.about {
  .banner {
    width: 100%;
    height: 720px;
    background: url('https://static.zhongjiadata.com/images/20250227/5eb6157d51bd4077a47b1c52bbe93497.png') no-repeat center center;
    background-size: cover;
    .banner-text {
      padding-top: 240px;
      width: 1384px;
      margin: 0 auto;
      .title {
        font-weight: 600;
        color: #fff;
        line-height: 84px;
        font-size: 60px;
      }
      .sub {
        width: 353px;
        height: 48px;
        background: #ffffff;
        border-radius: 7px;
        color: #418bdb;
        font-size: 24px;
        display: flex;
        align-items: center;
        padding-left: 14px;
        font-weight: 600;
        margin-bottom: 12px;
        .first {
          padding-right: 15px;
          margin-right: 15px;
          height: 34px;
          border-right: 1px solid #438ada;
        }
      }
      .tip {
        color: #ffffff;
        line-height: 27px;
        font-size: 20px;
      }
      .btn {
        width: 229px;
        height: 48px;
        background: #3873ff;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
}
.btn {
  width: 251px !important;
  .company {
    color: #2C6EFF;
  }
}
.topText.company {
  height: 200px;
  .title {
    font-size: 36px;
    color: #333;
    font-weight: 600;
    line-height: 50px;
  }
  .en {
    font-size: 26px;
  }
}
.companyText {
  // padding-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1280px;
  margin: 0 auto;
  .left {
    height: 338px;
    flex: 1;
    z-index: 1;
    .title {
      margin-bottom: 23px;
      color: #333;
      font-size: 40px;
      font-family: 'siyuan-medium' !important;
    }
    .text {
      color: #333;
      line-height: 22px;
      font-size: 16px;
      text-indent: 32px;
      padding-bottom: 30px;
      border-bottom: 1px solid #dfdfdf;
      position: relative;
      margin-bottom: 40px;
      &::after {
        content: '';
        display: block;
        height: 7px;
        width: 35px;
        background-color: #666666;
        bottom: 0;
        right: 0;
        position: absolute;
      }
    }
    .icon-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-item {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 16px;
        .img {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }
      }
    }
  }
  .right {
    margin-left: 65px;
    img {
      width: 520px;
      height: 338px;
    }
  }
}
</style>
